<template>
<div >
    <div class="mt-10" :class="$vuetify.breakpoint.lgAndUp?'ml-12 pl-12 mr-12' : 'ma-3 mt-10 mb-12 ' ">
    <h2 style="font-size: 36px;" :style="$vuetify.breakpoint.mdAndUp?'': 'text-align: center;'">Explore Our Products</h2><br>
    <!-- <div class="carousel-wrapper">
       <v-layout row style="overflow-y: hidden; display: flex; flex-wrap: nowrap; height: 280px; width:100%" class="ml-2">
           <v-card style="width:500px; height: 250px;" elevation="0" rounded="lg" min-width="500px" min-height="250px" class="secondary_white mr-4 mt-2 ml-0" v-for="product in products1" :key="product.title">hii</v-card>
       </v-layout>
       <v-layout row style="overflow: hidden; display: flex; flex-wrap: nowrap;height: 280px; width:100%" class="ml-2">
           <v-card style="width:500px; height: 250px;" elevation="0" rounded="lg" min-width="500px" min-height="250px" class="secondary_white mr-4 mt-2 ml-0" v-for="product in products2" :key="product.title">hii</v-card>
       </v-layout>

       <v-layout justify-center class="mt-10">
           <div v-for="i in slides" :key="i" ><v-icon small v-if="i == currentSlide" color="primary">fiber_manual_record</v-icon>
           <v-icon x-small v-else @click="slide(i)">fiber_manual_record</v-icon>
           </div>
       </v-layout> 
    </div> -->

    <!-- <v-carousel hide-delimiter-background light :show-arrows="false" cycle
      height="320">
         <template v-for="(item, index) in products"  > 
          <v-carousel-item v-if="(index + 1) % columns === 1 || columns === 1" 
                           :key="index" 
          > 
            <v-row class="flex-nowrap "  > 
              <template v-for="(n,i) in columns"> 
                <template v-if="(+index + i) < products.length"> 
                  <v-col :key="i"> 
                    <v-sheet v-if="(+index + i) < products.length" 
                             height="260px"
                    >
                      <v-row class="fill-height"
                             align="center"
                             justify="center"
                      >
                        <div class="display-3">
                            <v-card width="500px" height="250px" class="ma-2 pl-5 pa-5 secondary_white" elevation="0" rounded="xl" >
                                <h3 style="font-size:24px; font-weight: 500;">{{item.title}}</h3>
                                <p style="font-size:20px;line-height:28px;font-weight: 400;text-align: left; width: 75% " class="mt-2" >{{item.discription}}</p>
                            </v-card></div>
                      </v-row>
                    </v-sheet>
                  </v-col> 
                </template> 
              </template> 
            </v-row> 
          </v-carousel-item>  -->

          <v-row class="mb-12 pb-10">
            <v-col cols="4" md="4"  lg="2" v-show="$vuetify.breakpoint.mdAndUp">
              <v-list>
                <v-list-item v-for="api in apis" :key="api" >
                  <v-list-item-title style="font-size:18px; cursor: pointer;">{{api}}</v-list-item-title>
                </v-list-item>
              </v-list>

            </v-col>
            <v-col cols="12" sm="12" md="8" lg="10">
              <v-row justify-md="center" align-content-md="center" align-md="center">
            <div v-for="product in products" :key="product.title">
            <v-card  max-width="500px" min-width="300px" height="250px" class="ma-2 pl-8 pa-5 secondary_white" elevation="0" rounded="xl">
              <h3 style="font-size:24px; font-weight: 500;">{{product.title}}</h3>
              <p style="font-size:20px;line-height:28px;font-weight: 400;text-align: left; width: 75% " class="mt-2" >{{product.discription}}</p>


            </v-card>
            </div>
          </v-row>
            </v-col>
          </v-row>

    <v-layout class="mt-12 mb-12 pt-5" row>
            <h2 style="font-size: 35px; font-weight:600;" class="primary--text" :style="$vuetify.breakpoint.mdAndUp?'': 'text-align: center;'">Currency API</h2><br>
            <p style="font-size:18px;line-height:28px;font-weight: 400;text-align: left;  " class="mt-5" >Vivamus dictum fermentum porta. Aliquam iaculis sem nec ligula volutpat tempus. Interdum et malesuada fames ac ante ipsum primis in faucibus. Curabitur quis sagittis velit. Proin sit amet justo lobortis, venenatis nibh in, egestas risus. Fusce mattis euismod maximus. Sed vehicula ut lacus eu commodo. Vestibulum vel condimentum lectus. Etiam imperdiet auctor orci quis ornare. Phasellus hendrerit velit ut urna aliquet, eu scelerisque elit sollicitudin. Praesent vestibulum pretium arcu at cursus. Maecenas vestibulum ullamcorper lobortis. Nunc tortor metus, tincidunt eget lobortis vitae, malesuada vel sapien. Nam quis sagittis nequeVestibulum vel condimentum lectus. Etiam imperdiet auctor orci quis ornare. Phasellus hendrerit velit ut urna aliquet, eu scelerisque elit sollicitudin. Praesent vestibulum pretium arcu at cursus. </p><br>
            <v-row justify-lg="space-around" >
                <v-btn width="400px" class="mt-5" color="primary" v-show="!loggedIn" @click="gotoSignup">Subscribe For Free</v-btn>
                <v-btn width="400px" class="mt-5" :color="loggedIn?'primary':'secondary_white'">View Documentation</v-btn>

            </v-row>



    </v-layout>
    </div>

     <v-card class=" secondary_white fullCover mt-12 pt-12  pa-10 mb-10 "  > 
      <v-row>
        <v-col cols="12" lg="6">
          <h2 style="font-size: 28px;">Secure, Reliable and Future ready</h2>
          <h1 style="font-size: 36px; color:#828282; margin-left: 60px  " >Take your products further.</h1><br>

        </v-col>
        <v-col cols="6">
          
        </v-col>
      </v-row>

    </v-card>


</div>

</template>

<script>
export default {

    created: function() {
        //  this.width = window.innerWidth;
        //  console.log(this.width)
        //  this.width = this.width - 200; //margin and padding
        //  console.log(this.width)
        //  this.itemsOnScreen = Math.floor( this.width/500) //dividing by card width
        //  console.log(this.itemsOnScreen)
        //  this.slides = this.products.length/(this.itemsOnScreen * 2)
        //  console.log(this.slides)
    },

    data: () => ({
        // currentItem: 0,
        // width: 0,
        // itemsOnScreen: 0,
        // slides: 0,
        // currentSlide: 1,
        products: [
            {
                title: 'Latest API',
                discription: 'Build secure, performant, scalable and engaging digital experiences cost effectively with ready to use API’s and datasets. '
            },

            {
                title: 'History API',
                discription: 'Build secure, performant, scalable and engaging digital experiences cost effectively with ready to use API’s and datasets. '
            },

            {
                title: 'Convert API',
                discription: 'Build secure, performant, scalable and engaging digital experiences cost effectively with ready to use API’s and datasets. '
            },

            {
                title: 'Exchange API',
                discription: 'Build secure, performant, scalable and engaging digital experiences cost effectively with ready to use API’s and datasets. '
            },

            // {
            //     title: 'Curreny API',
            //     discription: 'Build secure, performant, scalable and engaging digital experiences cost effectively with ready to use API’s and datasets. '
            // },

            // {
            //     title: 'Curreny API',
            //     discription: 'Build secure, performant, scalable and engaging digital experiences cost effectively with ready to use API’s and datasets. '
            // },

            // {
            //     title: 'Curreny API',
            //     discription: 'Build secure, performant, scalable and engaging digital experiences cost effectively with ready to use API’s and datasets. '
            // },

            //  {
            //     title: 'Curreny API',
            //     discription: 'Build secure, performant, scalable and engaging digital experiences cost effectively with ready to use API’s and datasets. '
            // },
        ],

        apis: ['Currency APIs', 'ABC APIs', 'XYZ APIs', 'BCX Apis', 'WYZ APIs'],
    }),

    computed: {
        // products1: function(){
        //     return this.products.slice(this.currentItem, this.itemsOnScreen +1)
        // },

        // products2: function(){
        //     console.log(this.products.slice(4, this.itemsOnScreen + 1))
        //     return this.products.slice(this.currentItem + this.itemsOnScreen + 2)
        // }

    //     columns() {
    //   if (this.$vuetify.breakpoint.xl) {
    //     return 4;
    //   }

    //   if (this.$vuetify.breakpoint.lg) {
    //     return 3;
    //   }

    //   if (this.$vuetify.breakpoint.md) {
    //     return 2;
    //   }

    //   return 1;
    // }

     loggedIn: function(){
      return this.$store.state.auth.isLoggedIn;
    },


    },

    methods: {
        // slide: function(i){
        //     this.currentSlide = i
        //     this.currentItem = this.currentItem + ((i - 1) * this.itemsOnScreen * 2) 
        //     console.log(this.currentItem)
        // }

        gotoSignup: function(){
      this.$router.push({ name : 'signup'})
    },
    }

}
</script>

<style>
/* .carousel-wrapper{
    width: 100%;
    overflow: hidden;
}

.card {
  display: flex;
} */

</style>